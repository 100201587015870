
import {
    defineStore
} from 'pinia'


// 网路请求
import {
    getRecommendDta
} from '@/api/modules/recommend_api.js'
export const useRecommend = defineStore('recommend', {
    state: () => {
        return {
            recommendList: [], // 主页展示的
            loading: true, //loading
            total: 0, // 总数
            params: {
                limit: 20,
                regionId: '', // 地区
                info: '', // 关键字
                order: '', // 排序
            },
            homeParams: {
                regionId: ''
            }
        }
    },
    actions: {
        async initRecommendList(params, flag) {
            params.limit = 20
            // 如果已经发送过了 就不发送请求了
            if (this.recommendList.length == 0) {
                this.loading = true
                try {
                    let res = await getRecommendDta(params)
                    let {
                        status,
                        data
                    } = res
                    if (status === 1) {
                        this.recommendList = data.list
                        this.loading = false
                        this.total = data.total
                    }
                } catch (error) {
                    console.log(error);
                } finally {
                    return
                }
            }
            // 如果传了一个flag 强制发送请求 那么就再次发送请求
            if (!flag) return

            try {
                this.loading = true
                let res = await getRecommendDta(params)
                let {
                    status,
                    data
                } = res
                if (status === 1) {
                    this.recommendList = data.list
                    this.loading = false
                    this.total = data.total
                }
            } catch (error) {
                console.log(error);
            }
        },

        // 搜索
        async search() {
            this.loading = true
            this.params.e_title = 0;
            let res = await getRecommendDta(this.params)
            let {
                status,
                data
            } = res
            if (status == 1) {
                this.recommendList = data.list
                this.loading = false
                this.total = data.total
            } 33
        },

        // 点击字母排序
        async sort(e_title) {
            this.loading = true
            let res = await getRecommendDta({
                ...this.params,
                e_title: e_title.toUpperCase(),
            })
            let {
                status,
                data
            } = res
            if (status == 1) {
                this.recommendList = data.list
                this.loading = false
                this.total = data.total
            }
        }
    }
})